export const GAME_TITLE = "Myrtle";
export const GAME_SUBTITLE = "Harry Potter Character Guessing Game";
export const CORRECT_WORD_MESSAGE = "You Win!";
export const STATISTICS_TITLE = "Statistics";
export const GUESS_DISTRIBUTION_TEXT = "Guess Distribution";
export const NEW_WORD_TEXT = "New word in";
export const SHARE_TEXT = "Share";
export const TOTAL_TRIES_TEXT = "Total tries";
export const SUCCESS_RATE_TEXT = "Success rate";
export const CURRENT_STREAK_TEXT = "Current streak";
export const BEST_STREAK_TEXT = "Best streak";
