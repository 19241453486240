export default class Character {
  name;
  house;
  first_apperance;
  blood_status;
  species;
  profession;
  constructor(
    name: string,
    house:
      | "Gryffindor"
      | "Hufflepuff"
      | "Ravenclaw"
      | "Slytherin"
      | "Unknown"
      | "N/A",
    blood_status:
      | "Pure Blood"
      | "Half Blood"
      | "Pure Blood or Half Blood"
      | "Muggle Born"
      | "Muggle"
      | "Squib"
      | "Part Human"
      | "House Elf",
    species:
      | "Human"
      | "Giant"
      | "Half Giant"
      | "Ghost"
      | "Ghoul"
      | "House Elf"
      | "Veela"
      | "Goblin"
      | "Warewolf",
    first_apperance: 1 | 2 | 3 | 4 | 5 | 6 | 7,
    profession: string
  ) {
    this.name = name;
    this.house = house;
    this.blood_status = blood_status;
    this.first_apperance = first_apperance;
    this.species = species;
    this.profession = profession;
  }
}
