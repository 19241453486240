import { useState } from "react";
import { BaseModal } from "./BaseModal";
import { BuyMeACoffeeModal } from "./BuyMeACoffeeModal";
import venmoSvg from "../../images/vemo.svg";
import btcSvg from "../../images/Bitcoin.png";
import cashSvg from "../../images/Cash.svg";
import { ExampleModal } from "./ExampleModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleCopy: () => void;
};

const loadVenmo = () => {
  window.location.href = "https://venmo.com/harrypotterwordle?txn=pay";
  setTimeout(function () {
    window.location.href =
      "https://venmo.com/code?user_id=3565082432767948409&created=1655761769.411992&printed=1";
  }, 1000);
};

const loadCash = () => {
  window.location.href = "https://cash.app/$HarryPotterWordle";
};

export const InfoModal = ({ isOpen, handleClose, handleCopy }: Props) => {
  const [isBuyMeModalOpen, setBuyMeModalOpen] = useState(false);
  const [isExampleOpen, setExampleOpen] = useState(false);
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-md text-gray-500 dark:text-gray-300">
        <div className="info-text">
          <b>Guess the mystery Harry Potter Character</b> <br></br>
        </div>
        <br />
        <ul>
          <li>
            <div className="info-text">
              You have eight guesses to get it right
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-correct" style={{ color: "white" }}>
                Green in any column
              </span>
              &nbsp;indicates a match!
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-almost">
                Yellow in the <b>blood status</b> column
              </span>
              &nbsp;indicates a partial match to the character. i.e., You
              guessed Half Blood, and they are Pure Blood.
              {/* <br></br>Options
              are: 'Pure Blood', 'Half Blood', 'Pure Blood or Half Blood' (we
              dont know for sure which), 'Muggle Born', 'Muggle', 'Squib', 'Part
              Human', or 'House Elf' */}
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-almost">
                Yellow in the <b>first appereance</b> column
              </span>
              &nbsp;indicates this attribute is within 2 books of the character.
            </div>
          </li>
          <li>
            <br></br>
            <div
              className="text-blue-500 cursor-pointer"
              onClick={() => setExampleOpen(true)}
            >
              Click here for an example
            </div>
          </li>
          <li>
            <br></br>
            <div className=" mt-4">
              <span className="text-gray-900 dark:text-gray-100 font-medium">
                Like the Site? Buy me a Butterbeer!
              </span>
              <div>
                <button
                  className="focus:outline-none"
                  onClick={() => loadVenmo()}
                >
                  <img className="venmo mx-1.5" src={venmoSvg} alt="" />
                </button>
                <button
                  className="focus:outline-none"
                  onClick={() => loadCash()}
                >
                  <img className="venmo" src={cashSvg} alt="" />
                </button>

                <button
                  className="focus:outline-none"
                  onClick={() => setBuyMeModalOpen(true)}
                >
                  <img className="venmo mx-2" src={btcSvg} alt="" />
                </button>
                <a className="flex justify-center" href="https://www.buymeacoffee.com/zachb" target="_blank"><img className="h-10 display:none" src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Butterbeer" /></a>
              </div>
            </div>
            <div>
              For daily hints follow us at
              <a
                href="https://twitter.com/HPWordle?ref_src=twsrc%5Etfw"
                className="twitter-follow-button text-blue-500 border-transparent focus:outline-none"
                data-show-count="false"
              >
                &nbsp;@HPWordle
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                // charset="utf-8"
              ></script>
            </div>
          </li>
        </ul>
      </p>

      {/* <button
        className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm focus:outline-none"
        onClick={() => setBuyMeModalOpen(true)}
      >
        ☕ Buy Me a Coffee
      </button> */}
      <BuyMeACoffeeModal
        isOpen={isBuyMeModalOpen}
        handleClose={() => setBuyMeModalOpen(false)}
        handleCopy={() => handleCopy()}
      />
      <ExampleModal
        isOpen={isExampleOpen}
        handleClose={() => setExampleOpen(false)}
      />
    </BaseModal>
  );
};
