import Character from "../model/Character";

export const CHARACTERS = [
  new Character(
    "Ron Weasley",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),

  new Character(
    "Rebeus Hagrid",
    "Gryffindor",
    "Part Human",
    "Half Giant",
    1,
    "Teacher"
  ),
  new Character(
    "Cedric Diggory",
    "Hufflepuff",
    "Pure Blood or Half Blood",
    "Human",
    3,
    "Student"
  ),
  new Character("Argus Filch", "N/A", "Squib", "Human", 1, "Caretaker"),
  new Character(
    "'Moaning' Myrtle Elizabeth Warren",
    "Ravenclaw",
    "Muggle Born",
    "Ghost",
    2,
    "Ghost"
  ),
  new Character(
    "Sir Nicholas de Mimsy-Porpington",
    "Gryffindor",
    "Pure Blood or Half Blood",
    "Ghost",
    1,
    "Ghost"
  ),

  new Character(
    "Harry Potter",
    "Gryffindor",
    "Half Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Ludo Bagman",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Head of the Department of Magical Games and Sports"
  ),
  new Character(
    "Alastor 'Mad-Eye' Moody",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Auror"
  ),
  new Character(
    "Narcissa Malfoy",
    "Slytherin",
    "Pure Blood",
    "Human",
    1,
    "Mother"
  ),
  new Character(
    "Gellert Grindelwald",
    "N/A",
    "Pure Blood",
    "Human",
    2,
    "Imprisoned"
  ),
  new Character("Dobby", "N/A", "House Elf", "House Elf", 2, "House Elf"),
  new Character(
    "Draco Malfoy",
    "Slytherin",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Lucius Malfoy",
    "Slytherin",
    "Pure Blood",
    "Human",
    2,
    "School Governor"
  ),

  new Character(
    "Barty Crouch Jr.",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Imprisoned"
  ),
  new Character(
    "Lavender Brown",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Hermione Granger",
    "Gryffindor",
    "Muggle Born",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Peter Pettigrew",
    "Gryffindor",
    "Pure Blood or Half Blood",
    "Human",
    3,
    "In Hiding"
  ),
  new Character("Dudley Dursley", "N/A", "Muggle", "Human", 1, "Student"),
  new Character(
    "Phineas Nigellus Black",
    "Slytherin",
    "Pure Blood",
    "Human",
    5,
    "Portrait"
  ),
  new Character(
    "Sirius Black",
    "Gryffindor",
    "Pure Blood",
    "Human",
    3,
    "Imprisoned"
  ),
  new Character(
    "Hannah Abbott",
    "Hufflepuff",
    "Pure Blood or Half Blood",
    "Human",
    1,
    "Student"
  ),

  new Character(
    "Amycus Carrow",
    "Slytherin",
    "Pure Blood or Half Blood",
    "Human",
    6,
    "Teacher"
  ),
  new Character(
    "Cho Chang",
    "Ravenclaw",
    "Pure Blood or Half Blood",
    "Human",
    3,
    "Student"
  ),
  new Character(
    "Vincent Crabbe",
    "Slytherin",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),

  new Character(
    "Dennis Creevey",
    "Gryffindor",
    "Muggle Born",
    "Human",
    4,
    "Student"
  ),
  new Character(
    "Barty Crouch Sr.",
    "Unknown",
    "Pure Blood",
    "Human",
    4,
    "Head of the Department of Magical Law Enforcement"
  ),

  new Character("Fleur Delacour", "N/A", "Part Human", "Veela", 4, "Student"),
  new Character(
    "Aberforth Dumbledore",
    "Unknown",
    "Half Blood",
    "Human",
    5,
    "Bar Owner"
  ),
  new Character(
    "Alecto Carrow",
    "Slytherin",
    "Pure Blood or Half Blood",
    "Human",
    6,
    "Teacher"
  ),
  new Character(
    "Viktor Krum",
    "N/A",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Student"
  ),

  new Character(
    "Vernon Dursley",
    "N/A",
    "Muggle",
    "Human",
    1,
    "Director at Grunnings"
  ),

  new Character(
    "Arabella Figg",
    "N/A",
    "Squib",
    "Human",
    1,
    "Dealer of cross-bred Cats and Kneazles"
  ),
  new Character(
    "Justin Finch-Fletchley",
    "Hufflepuff",
    "Muggle Born",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Seamus Finnigan",
    "Gryffindor",
    "Half Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Albus Dumbledore",
    "Gryffindor",
    "Half Blood",
    "Human",
    1,
    "Head Master"
  ),
  new Character(
    "Luna Lovegood",
    "Ravenclaw",
    "Pure Blood or Half Blood",
    "Human",
    5,
    "Student"
  ),
  new Character(
    "Cornelius Fudge",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    2,
    "Minister of Magic"
  ),
  new Character(
    "Mundungus Fletcher",
    "Unknown",
    "Half Blood",
    "Human",
    5,
    "Theif"
  ),
  new Character(
    "Filius Flitwick",
    "Ravenclaw",
    "Part Human",
    "Goblin",
    1,
    "Teacher"
  ),

  new Character(
    "Marvolo Gaunt",
    "Slytherin",
    "Pure Blood",
    "Human",
    6,
    "Retired"
  ),
  new Character(
    "Fenrir Greyback",
    "Unknown",
    "Part Human",
    "Warewolf",
    6,
    "Warewolf"
  ),
  new Character(
    "Colin Creevey",
    "Gryffindor",
    "Muggle Born",
    "Human",
    2,
    "Student"
  ),
  new Character(
    "Horace Slughorn",
    "Slytherin",
    "Pure Blood or Half Blood",
    "Human",
    6,
    "Teacher"
  ),

  new Character(
    "Gregory Goyle",
    "Slytherin",
    "Pure Blood",
    "Human",
    1,
    "Stuedent"
  ),

  new Character(
    "Igor Karkaroff",
    "N/A",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Teacher"
  ),
  new Character("Petunia Dursley", "N/A", "Muggle", "Human", 1, "Mother"),
  new Character(
    "Bellatrix Lestrange",
    "Slytherin",
    "Pure Blood",
    "Human",
    5,
    "Imprisoned"
  ),
  new Character(
    "Remus Lupin",
    "Gryffindor",
    "Part Human",
    "Warewolf",
    3,
    "Teacher"
  ),
  new Character(
    "Gilderoy Lockhart",
    "Ravenclaw",
    "Half Blood",
    "Human",
    2,
    "Teacher"
  ),
  new Character(
    "Neville Longbottom",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),

  new Character(
    "Minerva McGonagall",
    "Gryffindor",
    "Half Blood",
    "Human",
    1,
    "Teacher"
  ),
  new Character(
    "Pansy Parkinson",
    "Slytherin",
    "Pure Blood or Half Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "George Weasley",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),
  new Character("Marjorie Dursley", "N/A", "Muggle", "Human", 1, "Dog Breeder"),
  new Character(
    "Merope Gaunt",
    "Slytherin",
    "Pure Blood",
    "Human",
    6,
    "Mother"
  ),
  new Character(
    "Poppy Pomfrey",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    1,
    "Matron"
  ),
  new Character(
    "Kingsley Shacklebolt",
    "Unknown",
    "Pure Blood",
    "Human",
    5,
    "Auror"
  ),
  new Character(
    "Rita Skeeter",
    "Unknown",
    "Pure Blood or Half Blood",
    "Human",
    4,
    "Gossip Correspondent"
  ),

  new Character(
    "Severus Snape",
    "Slytherin",
    "Half Blood",
    "Human",
    1,
    "Teacher"
  ),
  new Character(
    "Nymphadora Tonks",
    "Hufflepuff",
    "Half Blood",
    "Human",
    5,
    "Auror"
  ),
  new Character(
    "Sybill Trelawney",
    "Ravenclaw",
    "Half Blood",
    "Human",
    3,
    "Teacher"
  ),
  new Character(
    "Dolores Umbridge",
    "Slytherin",
    "Half Blood",
    "Human",
    5,
    "Teacher"
  ),
  new Character(
    "Tom Riddle",
    "Slytherin",
    "Half Blood",
    "Human",
    1,
    "In Hiding"
  ),

  new Character(
    "Ginevra Weasley",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "Xenophilius Lovegood",
    "Ravenclaw",
    "Pure Blood or Half Blood",
    "Human",
    7,
    "Editor"
  ),
  new Character(
    "Fred Weasley",
    "Gryffindor",
    "Pure Blood",
    "Human",
    1,
    "Student"
  ),
  new Character(
    "The Fat Friar",
    "Hufflepuff",
    "Pure Blood or Half Blood",
    "Ghost",
    1,
    "Ghost"
  ),
];
