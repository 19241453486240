import Character from "../../model/Character";
import { isWinningCharacter, solution } from "../../lib/character_logic";
import ReactDOM from "react-dom";

type Props = {
  characterGuess: Character;
  exampleOne?: boolean;
  exampleTwo?: boolean;
};

export const Guess = ({ characterGuess, exampleOne, exampleTwo }: Props) => {
  let correctName = characterGuess.name === solution.name;
  let correctHouse = characterGuess.house === solution.house;
  let correctBloodStatus =
    characterGuess.blood_status === solution.blood_status;
  let correctSpecies = characterGuess.species === solution.species;
  let correctFirstApperance =
    characterGuess.first_apperance === solution.first_apperance;

  let almostCorrectBloodStatus = () => {
    if (
      (solution.blood_status === "Half Blood" ||
        solution.blood_status === "Pure Blood" ||
        solution.blood_status === "Pure Blood or Half Blood") &&
      (characterGuess.blood_status === "Half Blood" ||
        characterGuess.blood_status === "Pure Blood" ||
        characterGuess.blood_status === "Pure Blood or Half Blood")
    ) {
      return true;
    }
    return false;
  };

  let almostCorrectFirstAppearance = () => {
    const difference = Math.abs(
      solution.first_apperance - characterGuess.first_apperance
    );
    if (difference < 3) return true;
    return false;
  };

  if (exampleOne) {
    correctName = false;
    correctHouse = false;
    correctBloodStatus = false;
    correctSpecies = true;
    correctFirstApperance = false;

    almostCorrectBloodStatus = () => {
      return true;
    };

    almostCorrectFirstAppearance = () => {
      return true;
    };
  }

  if (exampleTwo) {
    correctName = false;
    correctHouse = true;
    correctBloodStatus = true;
    correctSpecies = true;
    correctFirstApperance = true;

    almostCorrectBloodStatus = () => {
      return true;
    };

    almostCorrectFirstAppearance = () => {
      return true;
    };
  }

  return (
    <div className="guess grid grid-cols-4 sm:grid-cols-5  border-b-2 border-slate-800 items-center">
      <div
        className={
          "character-tile justify-left sm:justify-center col-span-4 sm:col-span-1 bg-stone-100 " +
          (correctName ? "guess-correct" : "")
        }
      >
        <div className="character-guess text-left sm:text-center  sm:text-sm font-bold sm:font-normal">
          {characterGuess.name}
        </div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctHouse ? "guess-correct" : "")
        }
      >
        <div className="character-guess sm:text-sm">{characterGuess.house}</div>
      </div>
      <div
        className={
          "character-tile " +
          (correctBloodStatus ? "guess-correct" : "") +
          " " +
          (almostCorrectBloodStatus() && !correctBloodStatus
            ? "guess-almost"
            : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {characterGuess.blood_status}
        </div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctSpecies ? "guess-correct" : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {characterGuess.species}
        </div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctFirstApperance ? "guess-correct" : "") +
          " " +
          (almostCorrectFirstAppearance() && !correctFirstApperance
            ? "guess-almost"
            : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {characterGuess.first_apperance}
        </div>
      </div>
    </div>
  );
};
