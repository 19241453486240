import Character from "../model/Character";
import { solution } from "./character_logic";

export type CharStatus = "absent" | "present" | "correct";

const checkNames = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkHouses = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkBloodStatus = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else if (
    (solution === "Half Blood" ||
      solution === "Pure Blood" ||
      solution === "Pure Blood or Half Blood") &&
    (guess === "Half Blood" ||
      guess === "Pure Blood" ||
      guess === "Pure Blood or Half Blood")
  )
    return "present";
  else return "absent";
};

const checkSpecies = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkFirstAppreance = (guess: number, solution: number): CharStatus => {
  const difference = Math.abs(solution - guess);

  if (guess === solution) return "correct";
  else if (difference < 3) return "present";
  else return "absent";
};

export const getGuessStatuses = (guess: Character): CharStatus[] => {
  const statuses: CharStatus[] = Array.from(Array(5));
  statuses[0] = checkNames(guess.name, solution.name);
  statuses[1] = checkHouses(guess.house, solution.house);
  statuses[2] = checkBloodStatus(guess.blood_status, solution.blood_status);
  statuses[3] = checkSpecies(guess.species, solution.species);
  statuses[4] = checkFirstAppreance(
    guess.first_apperance,
    solution.first_apperance
  );

  return statuses;
};
