import { useState, useEffect, createRef, useRef } from "react";
import "./App.css";
import { Navbar } from "./components/navbar/Navbar";
import { NameInput } from "./components/input/NameInput";
import Character from "./model/Character";
import { Guess } from "./components/guesses/Guess";
import { Header } from "./components/guesses/Header";
import {
  GAME_TITLE,
  GAME_SUBTITLE,
  CORRECT_WORD_MESSAGE,
} from "./constants/strings";
import { isWinningCharacter, solution } from "./lib/character_logic";
import { InfoModal } from "./components/modals/InfoModal";
import { PayMeModal } from "./components/modals/PayMeModal";
import {
  GAME_LOST_INFO_DELAY,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from "./constants/settings";
import { AlertContainer } from "./components/alerts/AlertContainer";
import { useAlert } from "./context/AlertContext";
import {
  clearLocalStorage,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from "./lib/localStorage";
import { StatsModal } from "./components/modals/StatsModal";
import { addStatsForCompletedGame, loadStats } from "./lib/stats";
import { HintModal } from "./components/modals/HintModal";

function App() {
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert();
  const [currentGuess, setCurrentGuess] = useState<Character>();
  const [isGameWon, setIsGameWon] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [guesses, setGuesses] = useState<Character[]>(() => {
    const loaded = loadGameStateFromLocalStorage();
    if (!loaded) return [];

    if (loaded.solution.name !== solution.name) {
      console.log(loaded.solution);
      console.log(solution);
      return [];
    }
    const gameWasWon =
      loaded.guesses.filter((e) => e.name === solution.name).length > 0;
    if (gameWasWon) {
      setIsGameWon(true);
    }

    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true);
      showErrorAlert("The character was " + solution.name, {
        persist: true,
      });
    }

    return loaded.guesses;
  });

  const [stats, setStats] = useState(() => loadStats());

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isPayMeModalOpen, setPayMeModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);

  const displayHeader = guesses?.length !== undefined && guesses?.length > 0;
  const displayHelp = guesses?.length !== undefined && guesses?.length > 1;

  const onSettingsClick = () => {
    setGuesses([]);
    setIsGameWon(false);
    setIsGameLost(false);

    // clearLocalStorage();
  };

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true);
      }, WELCOME_INFO_MODAL_MS);
    } else {
      setTimeout(() => {
        setPayMeModalOpen(true);
      }, WELCOME_INFO_MODAL_MS);
    }
  }, []);

  useEffect(() => {
    divRef2.current?.focus();
    if (guesses !== undefined)
      saveGameStateToLocalStorage({ guesses, solution });
  }, [guesses]);

  useEffect(() => {
    if (isGameWon) {
      showSuccessAlert(CORRECT_WORD_MESSAGE, {
        delayMs: REVEAL_TIME_MS,
        onClose: () => setIsStatsModalOpen(true),
      });
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true);
      }, GAME_LOST_INFO_DELAY);
    }
  }, [isGameWon, isGameLost, showSuccessAlert]);

  const onCharacterGuess = (characterGuess: Character) => {
    checkIfWon(characterGuess);
  };

  const checkIfWon = (guess: Character) => {
    if (isGameWon || isGameLost) {
      return;
    }

    if (guesses?.includes(guess)) {
      showErrorAlert("You have already guessed that character");
      return;
    }

    setGuesses([...(guesses ?? []), guess]);
    setCurrentGuess(guess);

    if (guess === solution) {
      setStats(addStatsForCompletedGame(stats, guesses.length));
      return setIsGameWon(true);
    }

    if (guesses?.length === MAX_CHALLENGES - 1) {
      setStats(addStatsForCompletedGame(stats, guesses.length + 1));
      setIsGameLost(true);
      showErrorAlert("The character was " + solution.name, {
        persist: true,
        delayMs: REVEAL_TIME_MS,
      });
    }
  };

  const divRef = useRef<null | HTMLDivElement>(null);
  const divRef2 = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (divRef != null && divRef.current !== null) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
    divRef2.current?.focus();
  });

  return (
    <div className="">
      <Navbar
        setIsInfoModalOpen={setIsInfoModalOpen}
        setIsStatsModalOpen={setIsStatsModalOpen}
        onSettingsClick={onSettingsClick}
      />
      <p className="text-3xl  font-bold dark:text-white flex justify-center">
        {GAME_TITLE}
      </p>
      <p className="text-l mb-4 dark:text-white flex justify-center">
        {GAME_SUBTITLE}
      </p>
      <div className="flex justify-center mb-6">
        <NameInput
          onCharacterGuess={onCharacterGuess}
          guesses={guesses ?? []}
          isGameWon={isGameWon}
        />
      </div>
      {displayHelp && (
        <div
          className="flex justify-center mb-4 text-gray-500 cursor-pointer"
          onClick={() => setIsHintModalOpen(true)}
        >
          <u>Get a Hint</u>
        </div>
      )}
      {displayHeader && <Header />}
      <div className="guesses">
        {guesses?.map((guess) => (
          <div ref={divRef2} key={guess.name}>
            <Guess characterGuess={guess} />
            <div ref={divRef} />
          </div>
        ))}
      </div>

      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
        handleCopy={() => showSuccessAlert("Copied")}
      />
      <PayMeModal
        isOpen={isPayMeModalOpen}
        handleClose={() => setPayMeModalOpen(false)}
        handleCopy={() => showSuccessAlert("Copied")}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShareToClipboard={() => showSuccessAlert("Copied")}
        // isHardMode={isHardMode}
        // isDarkMode={isDarkMode}
        // isHighContrastMode={isHighContrastMode}
        handleCopy={() => showSuccessAlert("Copied")}
      />
      <HintModal
        isOpen={isHintModalOpen}
        handleClose={() => setIsHintModalOpen(false)}
      />
      <AlertContainer />
    </div>
    // </div>
  );
}

export default App;
