import { useState } from "react";
import Countdown from "react-countdown";
import { StatBar } from "../stats/StatBar";
import { Histogram } from "../stats/Histogram";
import { GameStats } from "../../lib/localStorage";
import { ShareIcon } from "@heroicons/react/outline";
import { shareStatus } from "../../lib/share";
import { tomorrow } from "../../lib/character_logic";
import { BaseModal } from "./BaseModal";
import { BuyMeACoffeeModal } from "./BuyMeACoffeeModal";
import venmoSvg from "../../images/vemo.svg";
import btcSvg from "../../images/Bitcoin.png";
import cashSvg from "../../images/Cash.svg";
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from "../../constants/strings";
import Character from "../../model/Character";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: Character[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShareToClipboard: () => void;
  // isHardMode: boolean;
  // isDarkMode: boolean;
  // isHighContrastMode: boolean;
  handleCopy: () => void;
};

const loadVenmo = () => {
  window.location.href = "https://venmo.com/harrypotterwordle?txn=pay";
  setTimeout(function () {
    window.location.href =
      "https://venmo.com/code?user_id=3565082432767948409&created=1655761769.411992&printed=1";
  }, 1000);
};

const loadCash = () => {
  window.location.href = "https://cash.app/$HarryPotterWordle";
};

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  // isHardMode,
  // isDarkMode,
  // isHighContrastMode,
  handleCopy,
}: Props) => {
  const [isBuyMeModalOpen, setBuyMeModalOpen] = useState(false);
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    );
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h5 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h5>
      <Histogram gameStats={gameStats} />
      {(isGameLost || isGameWon) && (
        <div>
          <div className="">
            {/* <span className="text-gray-900 dark:text-gray-100 font-medium ">
              Like the Site? Buy me a Butterbeer!
            </span> */}
            {/* <div className="flex grid text-gray-900 dark:text-gray-100 font-medium ">
              There&#39;d be no game without you!<br></br> But also... without
              me. Send me a dollar!
            </div> */}
            <div className="flex grid text-gray-900 dark:text-gray-100 font-medium ">
              Like Dumbledore's patronus rises from the ashes... this website
              rises from a server that costs money. <br></br>
              Help me keep it ad free! Venmo me a dollar. McGonagall would
              approve.
            </div>
            <div>
              <button
                className="focus:outline-none"
                onClick={() => loadVenmo()}
              >
                <img className="venmo mx-1.5" src={venmoSvg} alt="" />
              </button>
              <button className="focus:outline-none" onClick={() => loadCash()}>
                <img className="venmo" src={cashSvg} alt="" />
              </button>

              <button
                className="focus:outline-none"
                onClick={() => setBuyMeModalOpen(true)}
              >
                <img className="venmo mx-2" src={btcSvg} alt="" />
              </button>
              <a className="flex justify-center" href="https://www.buymeacoffee.com/zachb" target="_blank"><img className="h-10 display:none" src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Butterbeer" /></a>
            </div>
          </div>
          <div className="mt-2 sm:mt-2 columns-2 dark:text-white grid grid-cols-2">
            <div>
              <div className="mt-0"></div>
              <div>
                <h5>{NEW_WORD_TEXT}</h5>
                <Countdown
                  className="text-lg font-medium text-gray-900 dark:text-gray-100"
                  date={tomorrow}
                  daysInHours={true}
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className="inline-flex justify-center items-center text-center mt-2 w-full border-solid border-2 rounded-lg border-indigo-600 px-4 py-2 text-base font-medium text-gray-900 dark:text-gray-100 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={() => {
                  shareStatus(
                    guesses,
                    isGameLost,
                    false,
                    false,
                    false,
                    handleShareToClipboard
                  );
                }}
              >
                <ShareIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                {SHARE_TEXT}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <p className="dark:text-white">
          Looking for more of a challenge? Try our{" "}
          <a
            className="text-blue-500 focus:outline-none"
            href="https://www.harrypotterwordle.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            standard wordle
          </a>
          &nbsp;or our&nbsp;
          <a
            className="text-blue-500 focus:outline-none"
            href="https://dynamic.harrypotterwordle.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            varying length wordle.
          </a>
        </p>
      </div>
      <div className="mt-2">
        <div>
          For daily hints follow us at
          <a
            href="https://twitter.com/HPWordle?ref_src=twsrc%5Etfw"
            className="twitter-follow-button text-blue-500"
            data-show-count="false"
          >
            &nbsp;@HPWordle
          </a>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            // charset="utf-8"
          ></script>
        </div>
      </div>
      <BuyMeACoffeeModal
        isOpen={isBuyMeModalOpen}
        handleClose={() => setBuyMeModalOpen(false)}
        handleCopy={() => handleCopy()}
      />
    </BaseModal>
  );
};
