export const Header = () => {
  return (
    <div className="header grid grid-cols-4 sm:grid-cols-5 border-b-2 border-slate-400 ">
      <div className="hidden sm:block"></div>
      {/* <div className="header-item">Name</div> */}
      <div className="header-item">House</div>
      <div className="header-item">Blood Status</div>
      <div className="header-item">Species</div>
      <div className="header-item">First Appearence</div>
    </div>
  );
};
