import { CHARACTERS } from "../constants/characters";
import Character from "../model/Character";
import reorder from "./reorder";

export const isWinningCharacter = (character: Character) => {
  return solution === character;
};

export const getCharacterOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date(2022, 0).valueOf();
  const now = Date.now();
  const msInDay = 86400000;
  let index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;

  //override for testing
  // index = 1;
  // const reorderedList = reorder(CHARACTERS);

  return {
    solution: CHARACTERS[index % CHARACTERS.length],
    solutionIndex: index,
    tomorrow: nextday,
  };
};

export const { solution, solutionIndex, tomorrow } = getCharacterOfDay();
