import Character from "../../model/Character";
import { Guess } from "../guesses/Guess";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const ExampleModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Example" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex justify-center">
        <ul>
          <li>
            <div className="text-black">
              <br />
              <Guess
                characterGuess={
                  new Character(
                    "Harry Potter",
                    "Gryffindor",
                    "Half Blood",
                    "Human",
                    1,
                    "Student"
                  )
                }
                exampleOne={true}
              />
            </div>
          </li>
          <br />
          <li>
            <div>
              This tells us the character we are trying to guess is not in
              Gryffindor, they are not half blood but either pure blood or we
              dont know if they are pure blood or half blood, they are human and
              they are not in the first book but either the second book or the
              third book.
            </div>
          </li>
          <li>
            <div className="text-black">
              <br />
              <Guess
                characterGuess={
                  new Character(
                    "Cedric Diggory",
                    "Hufflepuff",
                    "Pure Blood or Half Blood",
                    "Human",
                    3,
                    "Student"
                  )
                }
                exampleTwo={true}
              />
            </div>
          </li>
          <br />
          <li>
            <div>
              This tells us the character we are trying to guess is in
              Hufflepuff, they are either pure blood or half blood (we don't
              know for sure which), they are human, their first appereance is in
              the third book, and it is not Cedric Diggory.
            </div>
          </li>
        </ul>
      </div>
    </BaseModal>
  );
};
