import { useState, useRef, useEffect } from "react";
import { BaseModal } from "./BaseModal";
import { BuyMeACoffeeModal } from "./BuyMeACoffeeModal";
import venmoSvg from "../../images/vemo.svg";
import btcSvg from "../../images/Bitcoin.png";
import cashSvg from "../../images/Cash.svg";

var goodToClose = false;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleCopy: () => void;
};

const loadVenmo = () => {
  window.location.href = "https://venmo.com/harrypotterwordle?txn=pay";
  setTimeout(function () {
    window.location.href =
      "https://venmo.com/code?user_id=3565082432767948409&created=1655761769.411992&printed=1";
  }, 1000);
};

const loadCash = () => {
  window.location.href = "https://cash.app/$HarryPotterWordle";
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function handleClose2(handleClose: () => void) {
  if (goodToClose) {
    handleClose();
  }
}

export const PayMeModal = ({ isOpen, handleClose, handleCopy }: Props) => {
  const [isBuyMeModalOpen, setBuyMeModalOpen] = useState(false);
  const getRandom = useRef(1);
  useEffect(() => {
    getRandom.current = getRandomInt(1);
  }, []);
  useEffect(() => {
    setInterval(() => {
      goodToClose = true;
    }, 3000);
  }, []);
  return (
    <BaseModal
      title="Help me keep the site ad free!"
      isOpen={isOpen}
      handleClose={() => handleClose2(handleClose)}
    >
      <div className=" mt-4">
        {getRandom.current === 0 && (
          <div className="flex grid text-gray-900 dark:text-gray-100 font-medium ">
          There once was a Wordle for Potter<br></br>
          I made it-it wasn't a bother<br></br>
          You enjoyed it today<br></br>
          So let me just say<br></br>
          Donate to the diaper fund for my daughter<br></br>
        </div>
        )}
        {getRandom.current === 1 && (
          <div className="flex grid text-gray-900 dark:text-gray-100 font-medium ">
            The TriWizard Tournament time has come<br></br>
            It's every five years so don't be dumb<br></br>
            The wizards will battle with spirit and fervor<br></br>
            The least you can do is venmo me 3 dollars for my server<br></br>
          </div>
        )}
        {getRandom.current === 2 && (
          <div className="flex grid text-gray-900 dark:text-gray-100 font-medium ">
            The TriWizard Tournament time has come<br></br>
            It's every five years so don't be dumb<br></br>
            The Wizards will battle with spirit and fevor<br></br>
            The least you can do is venmo me 3 dollars for my server<br></br>
          </div>
        )}
        <div>
          <button className="focus:outline-none" onClick={() => loadVenmo()}>
            <img className="venmo mx-1.5" src={venmoSvg} alt="" />
          </button>
          <button className="focus:outline-none" onClick={() => loadCash()}>
            <img className="venmo" src={cashSvg} alt="" />
          </button>

          <button
            className="focus:outline-none"
            onClick={() => setBuyMeModalOpen(true)}
          >
            <img className="venmo mx-2" src={btcSvg} alt="" />
          </button>
          <a className="flex justify-center" href="https://www.buymeacoffee.com/zachb" target="_blank"><img className="h-10 display:none" src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Butterbeer" /></a>
        </div>
      </div>

      <div className="dark:text-white">
        For questions, comments, concerns, message us at
        <a
          href="https://twitter.com/HPWordle?ref_src=twsrc%5Etfw"
          className="twitter-follow-button text-blue-500 border-transparent focus:outline-none"
          data-show-count="false"
        >
          &nbsp;@HPWordle
        </a>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          // charset="utf-8"
        ></script>
      </div>
      <BuyMeACoffeeModal
        isOpen={isBuyMeModalOpen}
        handleClose={() => setBuyMeModalOpen(false)}
        handleCopy={() => handleCopy()}
      />
    </BaseModal>
  );
};
